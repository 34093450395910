import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(
    private api: ApiService
  ) { }

  storeFeedback(body: Feedback): Observable<HttpResponse<any>> {
    return this.api.postWithJsonContent('/feedback/store', body);
  }
}

export class Feedback {
  constructor(
    public contentId: number,
    public roomNumber: string,
    public questionAnswer: {},
    public additionalText: {}
  ) { }
}
