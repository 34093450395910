export class WidgetType {
    private static All: WidgetType[] = [];

    static readonly NORMAL = new WidgetType('normal', '', false);
    static readonly FLIP = new WidgetType('flip', '', false);
    static readonly WEB = new WidgetType('web', '', false);
    static readonly STATIC = new WidgetType('static', '', false);
    static readonly MAP = new WidgetType('map', '/map', false);
    static readonly WEATHER = new WidgetType('weather', '/weather-current', false);
    static readonly FORECAST = new WidgetType('forecast', '/weather', false);
    static readonly FEEDBACK = new WidgetType('feedback', '/feedback', true);
    static readonly SLIDESHOW = new WidgetType('slideshow', '/slideshow', true);
    static readonly SMS = new WidgetType('sms', '/sms', true);
    static readonly EMAIL = new WidgetType('email', '/email', true);
    static readonly EVENTS = new WidgetType('events', '/events', true);
    static readonly DISH_SET = new WidgetType('dish-set', '/dish-set', true);

    private constructor(
        public readonly type: string,
        public readonly url: string,
        public readonly id: boolean
    ) {
        WidgetType.All.push(this);
    }

    public static parse(type: string): WidgetType {
        return WidgetType.All.find((t) => t.type === type);
    }

    public static all(): WidgetType[] {
        return WidgetType.All;
    }

    toString() {
        return this.type;
    }
}
